import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Grid, Stack, TablePagination, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useLazyQuery, useQuery } from '@apollo/client';
import gtm from 'src/lib/gtm';
import { ALLGROUPMATCHES, ALLGROUPSDATA, ALL_GROUPS_STANDINGS, DOWNLOAD_DUPR_CSV, DOWNLOAD_GROUPS_PDF, PARTICIPANTS, TOURNAMENT_GROUP_CATEGORIES } from 'src/graphql/queries';
import GroupTabs from './GroupsTabs';
import toast from 'react-hot-toast';
import { FilterList, SearchOutlined } from '@material-ui/icons';
import Loading from 'src/components/Loading';
import { Helmet } from 'react-helmet';
import useDebounce from 'src/utils/debounce';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomPopover from 'src/components/CustomComponents/CustomPopover';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import CustomSelect from 'src/components/CustomComponents/CustomSelect';
import type { ChangeEvent, MouseEvent } from 'react';
import useDebouncedTerm from 'src/hooks/useDebouncedTerm';
import { DownloadOutlined } from '@material-ui/icons';
import CustomFormInput from 'src/components/CustomComponents/CustomFormInput';
import { useTournament } from '../TournamentPage';

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#0A2540',
    fontStyle: 'normal',
    lineHeight: 'normal',
  },
  groupsContainer: {
    width: '100%',
    borderRadius: '12px',
  },
  container: {
    padding: '0px',
    gap: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  selectGroup: {
    width: '15%',
    height: '478px',
    flexShrink: 0,
    borderRadius: '6px 0px 0px 6px',
    borderTop: '1px solid #EDF1F6',
    borderBottom: '1px solid #EDF1F6',
    borderLeft: '1px solid #EDF1F6',
    background: '#FFF',
    padding: '10px',
  },

  groupBox: {
    display: 'flex',
    width: '100%',
    padding: '4px 8px',
    alignItems: 'center',
    borderRadius: '4px',
    background: '#FAFAFA',
  },
  groupTitle: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    whiteSpace: 'nowrap',
  },
}));

const Groups = () => {
  const classes = useStyles();
  const { id, tournament, setRefetch } = useTournament();
  const { t } = useTranslation();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [filtersParams, setFiltersParams] = useSearchParams();
  const printAllGroupsRef = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState(filtersParams.get('name') || '');
  const [updatedGroupsData, setUpdatedGroupsData] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState<any>({}); // State to manage selected group
  const [selectedGroupArray, setSelectedGroupArray] = useState([]); // State to manage selected group
  const [selectedCat, setSelectedCat] = useState(null);
  const [selectedQ, setSelectedQ] = useState<string>(filtersParams.get('segment') || '');
  const [isDownload, setIsDownload] = useState(false);
  const [page, setPage] = useState(filtersParams.get('page') ? parseInt(filtersParams.get('page')) - 1 : 0);
  const [limit, setLimit] = useState(filtersParams.get('limit') ? parseInt(filtersParams.get('limit')) : 8);
  const debouncedSearch = useDebouncedTerm(search, 1500);
  const [selectedTab, setSelectedTab] = useState(filtersParams.get('view') ?? 'standard');
  const {
    loading: catLoading,
    data: filteredCategories,
    refetch: refetchCategories,
  } = useQuery(TOURNAMENT_GROUP_CATEGORIES, {
    variables: {
      filter: { tournament: id },
    },
    onCompleted: (data) => {
      const categories = data?.tournamentGroupCategories;
      setSelectedValue((prev) => categories?.find((item) => item?.id === Number(prev))?.id ?? categories[0]?.id);
    },
  });
  const [selectedValue, setSelectedValue] = useState<any>(filtersParams.get('category') || null);
  const [generatePDF] = useLazyQuery(DOWNLOAD_GROUPS_PDF);

  const handleSelectChange = (value) => {
    const selectedGroupObject = tournament?.tournamentCategory?.find((cat) => cat?.id == value);

    setSelectedCat(selectedGroupObject);
    setPage(0);
  };

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  useEffect(() => {
    if (tournament) {
      gtm.push({ event: 'page_view' });
    }
  }, [tournament]);

  const {
    loading: groupsLoading,
    data: groupsTableData,
    refetch: refetchGroups,
  } = useQuery(selectedTab == 'liveStanding' ? ALL_GROUPS_STANDINGS : ALLGROUPSDATA, {
    fetchPolicy: 'network-only',
    variables: {
      ...(debouncedSearch && { name: debouncedSearch }),
      filter: {
        tournament: id,
        ...(selectedValue || selectedCat
          ? {
              tournamentCategory: parseInt(selectedValue) || parseInt(selectedCat?.id),
            }
          : {}),
        ...(selectedQ ? { segment: selectedQ } : {}),
      },
      page: page + 1,
      limit: limit,
      queryName: selectedTab == 'liveStanding' ? 'allGroupsStandings' : 'allGroups',
    },
  });

  const totalGroups = groupsTableData?.allGroups?.total ?? groupsTableData?.allGroupsStandings?.total;
  const { data: participants, refetch: refetchParticipants } = useQuery(PARTICIPANTS, {
    variables: {
      filter: {
        tournament: id,
        status: 'confirm',
      },
    },
  });
  const {
    loading: matchesLoading,
    data: matchesTableData,
    refetch: refetchMatches,
  } = useQuery(ALLGROUPMATCHES, {
    variables: {
      ...(debouncedSearch && { name: debouncedSearch }),
      filter: {
        tournament: id,
        ...(selectedValue || selectedCat
          ? {
              tournamentCategory: parseInt(selectedValue) || parseInt(selectedCat?.id),
            }
          : {}),
        ...(selectedQ ? { segment: selectedQ } : {}),
      },
      page: page + 1,
      limit: limit,
    },
    // onCompleted: (data) => {
    //   setTournamentData(data.tournament);
    // },
  });
  const participantsFullNames = participants
    ? (Array.from(new Set(participants?.tournamentRegistrations?.flatMap((participant) => participant?.users?.map((user) => user?.user?.surname)))) as string[])
    : [];
  const [generateDUPRPDF] = useLazyQuery(DOWNLOAD_DUPR_CSV, {
    variables: {
      id: String(id),
      ...(selectedValue || selectedCat
        ? {
            tournamentCategory: String(selectedValue) || String(selectedCat?.id),
          }
        : {}),
      ...(selectedQ ? { segment: selectedQ } : {}),
      isManager: false,
    },
  });
  const getGroupsDataName = () => {
    if (selectedTab == 'liveStanding') {
      return groupsTableData?.allGroupsStandings;
    } else {
      return groupsTableData?.allGroups;
    }
  };
  const insertMatchesArrayAndGetUpdatedGroups = (matchesArray) => {
    const updatedGroups = getGroupsDataName()?.groups?.map((group) => {
      const updatedMatches = matchesArray?.filter((match) => {
        return match?.id === group?.id;
      });
      return {
        ...group,
        matches: updatedMatches ? updatedMatches[0]?.matches : [],
      };
    });
    return updatedGroups;
  };

  const updateGroupsData = () => {
    const updatedGroups = insertMatchesArrayAndGetUpdatedGroups(matchesTableData?.allGroupMatches?.groups);
    setSelectedGroupArray(updatedGroups);
    setUpdatedGroupsData(updatedGroups);
  };
  useEffect(() => {
    if (updatedGroupsData && updatedGroupsData.length > 0) {
      setSelectedGroup(null);
      setSelectedGroupArray(updatedGroupsData);
    }
  }, [updatedGroupsData]);

  useEffect(() => {
    updateGroupsData();
  }, [groupsTableData, matchesTableData]);
  const handleAllGroupsSelection = () => {
    setSelectedGroup(null);
    setSelectedGroupArray(updatedGroupsData);
  };

  const handleGroupSelection = (group) => {
    if (group === 'all') {
      handleAllGroupsSelection();
    } else {
      setSelectedGroup(group);
      setSelectedGroupArray([group]);
    }
  };

  const handleSelectChangeQ = (value) => {
    setSelectedQ(value);
    setPage(0);
  };

  function convertToAbbreviation(fullString) {
    const words = fullString?.match(/\[[^\]]+\]|\S+/g);
    const abbreviation = words
      .map((word) => {
        if (word.startsWith('[') && word.endsWith(']')) {
          return word;
        }
        return word.charAt(0).toUpperCase();
      })
      .join('');

    return abbreviation;
  }
  const handleDownload = (pdfFormat) => {
    toast.promise(
      generatePDF({
        variables: {
          id: String(id),
          ...(selectedValue || selectedCat
            ? {
                tournamentCategory: String(selectedValue) || String(selectedCat?.id),
              }
            : {}),
          ...(selectedQ ? { segment: selectedQ } : {}),
          pdfFormat: pdfFormat,
        },
      }),
      {
        loading: 'Generating PDF...',
        success: (res) => {
          if (res?.data) {
            const link = document.createElement('a');
            link.href = res?.data?.PdfGenerationForGroup;
            link.download = 'file.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          return <b>PDF being generated...</b>;
        },
        error: <b>Failed to generate PDF</b>,
      },
    );
  };
  const handleDownloadDUPR = () => {
    toast.promise(generateDUPRPDF(), {
      loading: 'Generating PDF...',
      success: (res) => {
        if (res?.data) {
          const link = document.createElement('a');
          link.href = res?.data?.CsvGenerationForPickleballGroups;
          link.download = 'file.pdf';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        return <b>PDF being generated...</b>;
      },
      error: <b>Failed to generate PDF</b>,
    });
  };
  useDebounce(
    () => {
      const currentParams = Object.fromEntries(filtersParams.entries());
      const newParams = {
        ...currentParams,
        ...(selectedValue ? { category: selectedValue } : {}),
        ...(selectedQ ? { segment: selectedQ } : {}),
        ...(debouncedSearch ? { name: debouncedSearch } : {}),
        page: String(page + 1),
        limit: String(limit),
      };

      if (!selectedValue) {
        delete newParams.category;
      }
      if (!selectedQ) {
        delete newParams.segment;
      }
      if (!debouncedSearch) {
        delete newParams.name;
      }
      setFiltersParams(newParams);
    },
    [selectedValue, selectedQ, limit, page, debouncedSearch],
    500,
  );

  useEffect(() => {
    const refetchData = () => {
      void refetchMatches();
      void refetchGroups();
      void refetchCategories();
      toast.success('Groups data has been successfully refreshed.');
    };

    setRefetch(refetchData);

    return () => setRefetch(undefined);
  }, []);

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
    const currentParams = Object.fromEntries(filtersParams.entries());
    const newParams = {
      ...currentParams,
      page: String(newPage + 1),
    };
    setFiltersParams(newParams);
    // setQuery('');
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const currentParams = Object.fromEntries(filtersParams.entries());
    const newParams = {
      ...currentParams,
      limit: String(limit + 1),
    };

    // Remove parameters with empty values
    if (!limit) {
      delete newParams.limit;
    }

    const newLimit = parseInt(event.target.value, 10);

    if (newLimit === -1) {
      setLimit(groupsTableData?.allGroups?.total);
    } else {
      setFiltersParams(newParams);
      setLimit(newLimit);
    }
    setPage(0);
    // setQuery('');
  };
  useEffect(() => {
    setPage(0);
  }, [selectedTab]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{tournament?.title ?? ''} | Groups</title>
      </Helmet>
      <Box className={classes.groupsContainer} ref={anchorRef}>
        <Box
          sx={{
            justifyContent: 'space-between',
            display: { md: 'flex', xs: 'none' },
          }}
          className={classes.container}
        >
          <Box>
            <Typography variant="heading17" className={classes.title}>
              {t('Groups')}
            </Typography>
          </Box>
          <Grid
            container
            xs={12}
            xl={7}
            lg={10}
            md={10}
            spacing={1}
            sx={{
              justifyContent: 'flex-end',
            }}
          >
            <Grid item lg={3} md={3} sm={6} xs={5}>
              <CustomFormInput
                placeholder={t('Enter surname')}
                name="Enter surname"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                size="small"
                InputProps={{
                  endAdornment: <SearchOutlined sx={{ fontSize: '20px' }} />,
                }}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={5}>
              <CustomSelect
                placeholder="All Categories"
                options={
                  filteredCategories?.tournamentGroupCategories?.map((type) => ({
                    value: type?.id,
                    title: type.category?.name,
                  })) || []
                }
                onChange={(value) => {
                  handleSelectChange(value);
                  setSelectedValue(value);
                }}
                onClear={() => setSelectedValue('')}
                selectedValue={selectedValue}
                buttonProps={{
                  // size: 'medium',
                  sx: { width: '100%' },
                }}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={5}>
              <CustomSelect
                placeholder={t('All Segments')}
                options={segmentOptions?.map((category) => ({
                  value: category?.value,
                  title: t(category?.label),
                }))}
                selectedValue={selectedQ}
                onChange={handleSelectChangeQ}
                buttonProps={{
                  // size: 'medium',
                  sx: {
                    width: '100%',
                  },
                }}
                onClear={() => setSelectedQ('')}
              />
            </Grid>
            <Grid item sx={{ position: 'relative' }}>
              <CustomPopover
                triggerEl={
                  <CustomButton size="small" variant="outline" square>
                    <DownloadOutlined sx={{ fontSize: '18px' }} />
                  </CustomButton>
                }
              >
                <Stack gap="8px">
                  <CustomButton variant="primary" sx={{ width: '100%' }} size="small" onClick={() => handleDownload('Standard')}>
                    Download PDF Standard
                  </CustomButton>
                  <CustomButton variant="primary" sx={{ width: '100%' }} size="small" onClick={() => handleDownload('Colorized')}>
                    Download PDF Colored
                  </CustomButton>
                </Stack>
              </CustomPopover>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: { md: 'none', xs: 'flex' },
            justifyContent: 'space-between',
            alignItems: 'start',
            flexWrap: 'wrap',
          }}
        >
          <Box>
            <Typography variant="heading17" className={classes.title}>
              {t('Groups')}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <CustomSelect
              placeholder="All Categories"
              options={
                filteredCategories?.tournamentGroupCategories?.map((type) => ({
                  value: type?.id,
                  title: type.category?.name,
                })) || []
              }
              onChange={(value) => {
                handleSelectChange(value);
                setSelectedValue(value);
              }}
              onClear={() => setSelectedValue('')}
              selectedValue={selectedValue}
            />
            <CustomPopover
              align="right"
              modalOnMobile
              triggerEl={
                <CustomButton size="medium" variant="primary" shape="circle">
                  <FilterList />
                </CustomButton>
              }
            >
              <Grid
                container
                xs={12}
                xl={7}
                lg={12}
                md={12}
                spacing={1}
                sx={{
                  mt: {
                    lg: 0,
                    xs: 0,
                  },
                }}
                justifyContent="start"
              >
                <Grid
                  sx={{
                    paddingTop: '7px',
                    paddingLeft: '6px',
                    cursor: 'pointer',
                  }}
                  item
                  lg={7}
                  md={7}
                  xs={12}
                >
                  <CustomFormInput
                    placeholder={t('Enter surname')}
                    name="Enter surname"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    size="small"
                    InputProps={{
                      endAdornment: <SearchOutlined sx={{ fontSize: '20px' }} />,
                    }}
                  />
                </Grid>
                <Grid
                  sx={{
                    paddingTop: '7px',
                    paddingLeft: '6px',
                    cursor: 'pointer',
                  }}
                  item
                  lg={7}
                  md={7}
                  xs={5}
                >
                  <CustomSelect
                    placeholder="Select Group"
                    options={[
                      { value: 'all', title: 'All Groups' },
                      ...(updatedGroupsData
                        ? updatedGroupsData.map((group) => ({
                            value: group?.id,
                            title: group?.name,
                          }))
                        : []),
                    ]}
                    onChange={(selectedValue) => {
                      if (selectedValue === 'all') {
                        handleGroupSelection('all');
                      } else {
                        const selectedGroupObject = updatedGroupsData?.find((group) => group?.id == selectedValue);
                        handleGroupSelection(selectedGroupObject);
                      }
                    }}
                    selectedValue={selectedGroup?.id}
                    buttonProps={{
                      // size: 'medium',
                      sx: {
                        width: '100%',
                      },
                    }}
                  />
                </Grid>
                <Grid item lg={7} md={7} xs={5}>
                  <CustomSelect
                    placeholder="All Segments"
                    selectedValue={selectedQ}
                    onChange={handleSelectChangeQ}
                    options={segmentOptions.map((category) => ({
                      value: category?.value,
                      title: category?.label,
                    }))}
                    buttonProps={{
                      // size: 'medium',
                      sx: {
                        width: '100%',
                      },
                    }}
                    onClear={() => setSelectedQ('')}
                  />
                </Grid>
                <Grid
                  // sx={{
                  //   paddingTop: '7px',
                  //   paddingLeft: '6px',
                  //   cursor: 'pointer',
                  // }}
                  item
                  sm={6}
                  xs={2}
                >
                  <CustomPopover
                    triggerEl={
                      <CustomButton size="small" variant="outline" square sx={{ width: '100%' }}>
                        <DownloadOutlined sx={{ fontSize: '18px' }} />
                      </CustomButton>
                    }
                  >
                    <Stack gap="8px">
                      <CustomButton variant="primary" sx={{ width: '100%' }} size="small" onClick={() => handleDownload('Standard')}>
                        Download PDF Standard
                      </CustomButton>
                      <CustomButton variant="primary" sx={{ width: '100%' }} size="small" onClick={() => handleDownload('Colorized')}>
                        Download PDF Colored
                      </CustomButton>
                    </Stack>
                  </CustomPopover>
                </Grid>
                {/* {categories?.league?.league?.sports?.title?.toLowerCase() === 'pickleball' && (
                  <Grid
                    sx={{
                      paddingTop: '7px',
                      paddingLeft: '6px',
                      cursor: 'pointer',
                    }}
                    item
                    sm={6}
                    xs={12}
                  >
                    <CustomButton
                      shape="default"
                      variant="primary"
                      size="medium"
                      sx={{
                        width: '100%',
                      }}
                      onClick={() => handleDownloadDUPR()}
                    >
                      Export matches for DUPR
                    </CustomButton>
                  </Grid>
                )} */}
              </Grid>
            </CustomPopover>
          </Box>
        </Box>

        {/* </Container> */}
      </Box>
      <Box ref={printAllGroupsRef} sx={{ display: 'flex', mt: 2, width: '100%' }}>
        <Box className={classes.selectGroup} sx={{ display: { md: 'block', xs: 'none' } }}>
          <Box
            sx={{
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              height: '428px',
              cursor: 'pointer',
            }}
          >
            <Box
              className={classes.groupBox}
              onClick={() => handleGroupSelection('all')} // Handle click for "All Groups" selection
              style={{
                border: selectedGroup === null ? `1px solid ${process.env.REACT_APP_PRIMARY_COLOR}` : '',
                background: selectedGroup === null ? '#FAFAFA' : '#FFF',
              }}
            >
              <Typography className={classes.groupTitle}>{t('All Groups')}</Typography>
            </Box>
            {updatedGroupsData?.length > 0 &&
              updatedGroupsData?.map(
                (group) =>
                  !group?.hide &&
                  !group?.isDelete && (
                    <Box
                      key={group.id}
                      className={classes.groupBox}
                      onClick={() => handleGroupSelection(group)}
                      style={{
                        border: selectedGroup?.id === group.id ? `1px solid ${process.env.REACT_APP_PRIMARY_COLOR}` : '',
                        background: selectedGroup?.id === group.id ? '#FAFAFA' : '#FFF',
                      }}
                    >
                      <Typography className={classes.groupTitle}>{group?.name}</Typography>
                      {!selectedCat && (
                        <Tooltip placement="right" title={`${group?.tournamentCategory?.category?.name}`}>
                          <Box
                            sx={{
                              padding: '0px 8px',
                              position: 'relative',
                              display: 'flex',
                              fontSize: { xl: '10px', xs: '8px' },
                            }}
                          >
                            {convertToAbbreviation(group?.tournamentCategory?.category?.name)}
                          </Box>
                        </Tooltip>
                      )}
                    </Box>
                  ),
              )}
          </Box>
        </Box>

        <GroupTabs
          selectedGroupArray={selectedGroupArray}
          groupsLoading={groupsLoading}
          matchesLoading={matchesLoading}
          selectedCat={selectedCat}
          // highlightText={highlightText}
          // groupRefs={groupRefs}
          // userRefs={userRefs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </Box>
      <TablePagination
        component="div"
        count={totalGroups ?? 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[8, 16]}
        labelRowsPerPage={t('Groups per page')}
      />
    </React.Fragment>
  );
};

export default Groups;
const segmentOptions = [
  {
    label: 'Main',
    value: 'MD',
  },
  {
    label: 'Consolation',
    value: 'consolation',
  },
  {
    label: 'Qualification',
    value: 'Q',
  },
];
